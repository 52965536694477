const CalloutSuccessIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.3536 8.35355C18.5488 8.54882 18.5488 8.8654 18.3536 9.06066L10.3536 17.0607C10.1583 17.2559 9.84171 17.2559 9.64645 17.0607L5.64645 13.0607C5.45118 12.8654 5.45118 12.5488 5.64645 12.3536L6.35355 11.6464C6.54882 11.4512 6.8654 11.4512 7.06066 11.6464L10 14.5858L16.9393 7.64645C17.1346 7.45118 17.4512 7.45118 17.6464 7.64645L18.3536 8.35355Z"
			fill="#1AB51A"
		/>
	</svg>
)

export default CalloutSuccessIcon
